/*
 * @Author: hwu
 * @Date: 2020-12-31 16:09:54
 * @Description: 活动接口
 * @LastEditTime: 2020-12-31 16:12:44
 */

import { posHttp, cpHttp } from '@/utils/axios'

/**
 * 获取门店活动信息(菜谱页滚动)
 * @param parentId
 * @returns {*}
 */
export const listStorePromoTitleApi = (storeId) => posHttp.get(`/promo/getStorePromoTitle?storeId=${storeId}`)

/**
 * 获取门店活动信息(引导页)
 * @param parentId
 * @returns {*}
 */
export const listGuidePromoTitleApi = (storeId, userId) => posHttp.get(`/promo/guidePromoTitle?storeId=${storeId}&userId=${userId}`)

/**
 * 获取抢券活动详情
 * @param {String} promoId 活动Id
 * @param {String} userId 用户openId
 * @param {String} way 渠道
 * @param {String} wayId 渠道Id
 * @returns
 */
export const getPromoDetailApi = (promoId, userId, way, wayId) => cpHttp.get(`/coupon/detail`, { params: { promoId, userId, way, wayId } })
