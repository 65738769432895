<!--
 * @Author: hwu
 * @Date: 2022-01-29 16:17:47
 * @Description: 《用户服务协议》弹窗
 * @LastEditTime: 2024-05-27 11:47:46
-->

<template>
  <popup-box :show="show" position="bottom" showCloseButton :popupStyle="customStyle" :maskStyle="maskStyle" @onClose="onClose()">
    <div class="wh-popup-container">
      <div class="wh-popup-header">
        <div class="wh-popup-header_title text-center">
          <span class="title-text">用户服务协议</span>
        </div>
      </div>
      <div class="wh-popup-body with-header">
        <div class="wh-popup-content">
          <div class="article-box">
            <!-- <div class="article-title">用户服务协议</div> -->
            <div class="article-paragraph">
              <div class="article-paragraph-row">《用户服务协议》（以下简称“本协议”）是悠订、商家及用户所订立的协议，以约束商家与用户间就会员卡开通所享有的权利义务进行的约定及悠订、商家与用户间就个人信息授权使用及保护的有关约定。</div>
              <div class="article-paragraph-row"><span class="text-strong">您通过网络页面勾选确认本协议，即视为您同意接受本协议的全部内容。</span> 您在接受本协议前请仔细阅读协议全部内容，如不同意本协议内容，或无法准确理解协议条文的含义，请不要进行确认或后续系统操作。</div>
              <div class="article-paragraph-row">
                您同意，悠订有权随时对本协议内容进行变更（包括但不限于退卡规则的变更等），并以悠订平台的相关渠道通知用户予以公示，无需单独另行通知，变更的公告自公告载明的时间生效，并成为协议的一部分。<span class="text-strong">如您不同意变更规则，则应暂停使用本协议约定服务。</span
                >若在修订后的规定载明的生效日期当日或之后，您使用本协议约定服务，即表示您同意且接受该等届时有效的协议内容。
              </div>
            </div>
            <div class="article-paragraph">
              <div class="article-paragraph-title">一、定义</div>
              <div class="article-paragraph-row">1、会员：指以悠订平台入驻商家开通的店铺维度或品牌维度进行的会员体系管理，用户主动授权加入会员领取会员卡，且满足商家设定的等级规则即是取得相应等级的会员资格，获得相应的权益。</div>
              <div class="article-paragraph-row">2、会员等级规则：会员等级规则由每个商家自行维护并设定公示，用户可在会员中心页内查看会员规则。</div>
              <div class="article-paragraph-row">3、开卡须知：新用户可在悠订APP+小程序付费购买商家会员卡1张。由用户在开通会员时，与商家共同签署的有关商家会员服务的协议，主要约定商家为用户提供会员服务的内容。<span class="text-strong"> 会员的开通以您同意商家用户服务协议为前提，请您在购买之前仔细阅读。</span></div>
              <div class="article-paragraph-row">1）会员服务有效期：</div>
              <div class="article-paragraph-row">会员权益自成功购买之时起开通，会员服务有效期自开通之日起算，具体期限根据购买的会员卡而定。</div>
              <div class="article-paragraph-row">2）会员服务内容：</div>
              <div class="article-paragraph-row">详情见商家用户服务协议。</div>
              <div class="article-paragraph-row">3）会员服务使用方式：</div>
              <div class="article-paragraph-row">会员登录后，自助点餐可在点餐过程中直接使用；堂食（仅限手机支付）请登录悠订APP出示会员码后使用，截屏、复制、修改权益无效。</div>
              <div class="article-paragraph-row">4）会员服务开通后，<span class="text-strong">权益及优惠仅限本人使用，不可转赠，不可退换，不可兑换现金。</span></div>
              <div class="article-paragraph-row">5）<span class="text-strong">一旦您成功开通会员，悠订并不会因您中途主动取消服务或终止资格而退还任何费用。</span></div>
              <div class="article-paragraph-row">6）用户个人信息授权：</div>
              <div class="article-paragraph-row">
                您授权悠订将您的信息（包括姓名、性别、生日、邮箱、会员积分、等级、会员周期消费累计值）进行存储、展示，并提供给当前店铺及/或该品牌方（以下简称“商家”），用于商家为您计算正确的会员权益、并为您提供会员服务。同时，您知悉并同意向您推送商业信息，包括发送邮件、短信、致电、营销权益发放及其他营销方式（如需退订，可依据信息提供步骤完成）。悠订商家将依法使用您的上述信息，并对您的信息予以保密，如您不希望向商家提供以上信息的，请勿点击“开通会员”。<span
                  class="text-strong"
                  >请您注意仔细阅读，确定了解商家向您提供的《权益规则详情》。</span
                >
              </div>
              <div class="article-paragraph-row">7）注销提示：</div>
              <div class="article-paragraph-row">如您注销悠订账户，您与悠订平台商家会员的线上绑定关系将同时解除，您已获得的会员权益（含积分、等级等）将同步注销且不可恢复。</div>
              <div class="article-paragraph-row">8）责任承担：</div>
              <div class="article-paragraph-row">您知悉悠订是会员系统功能的中立技术服务方，就会员服务由用户与商家自行按约履行，并各自承担责任，如发生争议，由您与店铺经营者自行友好协商解决。</div>
            </div>
            <div class="article-paragraph">
              <div class="article-paragraph-row">
                关于技术服务可能会涉及商家或商家的第三方技术服务商进行技术对接。因品牌商或品牌商的第三方技术服务商使用您的信息产生的纠纷，或者品牌商或品牌商的第三方技术服务商违反相关法律法规，或您在使用服务过程中遇到了经济损失，请您和商家友好协商解决。
                如果无法友好协商解决的，均应提交上海国际经济贸易仲裁委员会（上海国际仲裁中心）按照申请仲裁时该会有效的仲裁规则进行仲裁。仲裁裁决是终局的并对当事方均有约束力。
              </div>
            </div>
            <div class="article-paragraph">
              <div class="article-paragraph-row"><span class="text-strong">本协议未尽事宜参照《悠订系统使用协议》、《隐私政策》及商家的《权益规则详情》执行。</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部栏 -->
  </popup-box>
</template>
<script>
import PopupBox from '@/components/common/PopupBox'
export default {
  name: 'member-service-agree-popup',
  components: { PopupBox },
  props: {
    show: { type: Boolean, required: true, default: false },
    showSafeArea: { type: Boolean, required: false, default: true },
    popupStyle: { type: String, required: false, default: '' },
    maskStyle: { type: String, required: false, default: '' }
  },
  data() {
    return {}
  },
  computed: {
    customStyle() {
      return `height:80vh;${this.popupStyle}`
    }
  },
  methods: {
    onClose(e) {
      this.$emit('update:show', false)
      this.$emit('onClose', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.article-box {
  width: 100%;
  padding: 24px;
  .article-title {
    width: 100%;
    margin-bottom: 24px;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: $color-text-main;
  }
  .article-date {
    width: 100%;
    margin-bottom: 16px;
    font-size: 28px;
    color: $color-text-main;
  }
  .article-paragraph {
    width: 100%;
    line-height: 48px;
    .article-paragraph-title {
      width: 100%;
      margin-bottom: 16px;
      font-size: 26px;
      font-weight: bold;
      color: $color-text-main;
    }
    .article-paragraph-row {
      width: 100%;
      padding: 0 24px;
      margin-bottom: 16px;
      font-size: 24px;
      color: $color-text-main;
      .text-strong {
        font-weight: bold;
        color: $color-text-main;
      }
    }
  }
}
</style>
