<!--
 * @Author: hwu
 * @Date: 2021-07-29 10:30:38
 * @Description: 会员卡 - 券活动详情
 * @LastEditTime: 2024-05-27 22:48:12
-->

<template>
  <div>
    <popup-box :show="show" position="bottom" popupStyle="height:80vh" showCloseButton @onClose="onClose">
      <div class="wh-popup-container">
        <div class="wh-popup-body">
          <div class="wh-popup-content">
            <div class="promo-image" v-if="promo.images.length > 0">
              <swiper class="swipe-box" :autoplay="false" :indicator-dots="true">
                <swiper-item v-for="(image, index) in promo.images" :key="index" class="swipe-item-box">
                  <div class="swipe-item">
                    <w-image fit="cover" class="swipe-item_img" :src="transferImage(image)" />
                  </div>
                </swiper-item>
              </swiper>
            </div>
            <div class="promo-info">
              <div class="promo-info-name">{{ promo.promoName }}</div>
              <div class="promo-info-desc font-family-Regular">{{ promo | promoDescFilter }}</div>
              <div v-if="!promo.salePrice || promo.discountRate" class="promo-info-price">
                <span v-if="!promo.salePrice" class="promo-info-price_num">免费领取</span>
                <span v-else class="promo-info-price_num">￥{{ promo.salePrice }}</span>
                <span class="promo-info-price_discount" v-if="promo.discountRate && promo.promoSet !== 34">{{ promo | discountRateFilter }}</span>
              </div>
              <div class="promo-info-tag">
                <div v-if="!promo.refundDaysLimit && promo.salePrice" class="promo-info-tag__item">随时退</div>
                <div v-if="promo.autoRefundStatus === 1" class="promo-info-tag__item">到期自动退</div>
                <div v-if="promo.presentStatus === 1" class="promo-info-tag__item">可转赠</div>
              </div>
            </div>
            <!-- 套餐商品 -->
            <div class="promo-item" v-if="promo.couponItemUnitMenus && promo.couponItemUnitMenus.length > 0">
              <div class="promo-item-unit" v-if="promo.couponItemUnitMenus && promo.couponItemUnitMenus.length > 0">
                <div class="unit-category" v-for="(category, index) in promo.couponItemUnitMenus" :key="index">
                  <div class="unit-category-name">
                    <span class="unit-category-name_text">{{ category.categoryName }}</span>
                    <span class="unit-category-name_count" v-if="category.isFixed !== 1">{{ category.totalNum }}选{{ category.selectNum }}</span>
                    <span class="unit-category-name_desc" v-if="category.isFixed !== 1 && category.selectNum > 1 && category.maxCount !== 1">（可复选）</span>
                  </div>
                  <div class="unit-category-item" v-for="(item, itemIndex) in category.unitItems" :key="itemIndex">
                    <div class="unit-category-item_left">
                      <span class="item-tag">·</span>
                      <span class="item-name font-family-Regular">{{ item.itemName }}</span>
                      <span class="item-name font-family-Regular" v-if="item.productionModel">({{ item.productionModel }})</span>
                      <span class="item-count font-family-Regular" v-if="category.isFixed === 1">（{{ item.count }}份）</span>
                    </div>
                    <div class="unit-category-item_right">
                      <span class="item-price">￥{{ item.price }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 单品商品 -->
            <div class="promo-item" v-if="promo.couponItems && promo.couponItems.length > 1">
              <div class="promo-item-unit">
                <div class="unit-category">
                  <div class="unit-category-name">
                    <span class="unit-category-name_text">下列商品</span>
                    <span class="unit-category-name_count">{{ promo.couponItems.length }}选{{ promo.totalItemCount }}</span>
                    <span class="unit-category-name_desc" v-if="promo.singleItemCount !== 1">（可复选）</span>
                  </div>
                  <div class="unit-category-item" v-for="(item, itemIndex) in promo.couponItems" :key="itemIndex">
                    <div class="unit-category-item_left">
                      <span class="item-tag font-family-Regular">·</span>
                      <span class="item-name font-family-Regular">{{ item.itemName }}</span>
                    </div>
                    <div class="unit-category-item_right">
                      <span class="item-price font-family-Regular">凭券￥{{ item.promoPrice }}</span>
                      <span class="item-price font-family-Regular">原价￥{{ item.itemPrice }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="promo-rule">
              <!-- 券包内的券 -->
              <div class="promo-rule-item" v-if="promo.promoSet === 38 && promo.couponPackages && promo.couponPackages.length > 0">
                <div class="promo-rule-item_title">券包包含：</div>
                <div class="promo-rule-item_detail">
                  <div class="detail-row" v-for="(sub, subIndex) in promo.couponPackages" :key="subIndex">
                    <span class="detail-row-icon">·</span>
                    <span class="detail-row-link">{{ sub.promoName }}</span>
                    <span class="detail-row-text margin-left-8">{{ sub.count }}张</span>
                  </div>
                </div>
              </div>
              <!-- 有效期， 券包不展示有效期 -->
              <div class="promo-rule-item" v-if="promo.promoSet !== 38">
                <div class="promo-rule-item_title">
                  <image class="promo-rule-item_title-icon" src="/static/images/youxiaoqi.png" mode="widthFix" />
                  <span>有效期：</span>
                </div>
                <div class="promo-rule-item_detail">
                  <div class="detail-row" v-if="effectHourDesc">
                    <span class="detail-row-icon">·</span>
                    <span class="detail-row-text">{{ effectHourDesc }}</span>
                  </div>
                  <div class="detail-row">
                    <span class="detail-row-icon">·</span>
                    <span class="detail-row-text" v-if="promo.couponEffectDays && !effectHourDesc">{{ buyTitle }}后{{ promo.couponEffectDays }}天有效</span>
                    <span class="detail-row-text" v-if="promo.couponEffectDays && effectHourDesc">生效后{{ promo.couponEffectDays }}天内有效</span>
                    <span class="detail-row-text" v-if="!promo.couponEffectDays">{{ promo.couponBeginDate }} 至 {{ promo.couponEndDate }}</span>
                  </div>
                </div>
              </div>
              <div class="promo-rule-item" v-if="promo.couponTimes && promo.couponTimes.length > 0">
                <div class="promo-rule-item_title">
                  <image class="promo-rule-item_title-icon" src="/static/images/keyongshiduan.png" mode="widthFix" />
                  <span>可用时段：</span>
                </div>
                <div class="promo-rule-item_detail">
                  <div class="detail-row" v-for="(time, timeIndex) in promo.couponTimes" :key="timeIndex">
                    <span class="detail-row-icon">·</span>
                    <span class="detail-row-text">{{ weekdayDesc(time.weekday) }} {{ timeRangeDesc(time) }}</span>
                  </div>
                </div>
              </div>
              <div class="promo-rule-item" v-if="promo.ineffectiveDateStr && promo.ineffectiveDateStr.length > 0">
                <div class="promo-rule-item_title">
                  <image class="promo-rule-item_title-icon" src="/static/images/keyongshiduan.png" mode="widthFix" />
                  <span>不可用日期：</span>
                </div>
                <div class="promo-rule-item_detail">
                  <div class="detail-row" v-for="(desc, descIndex) in promo.ineffectiveDateStr" :key="descIndex">
                    <span class="detail-row-icon">·</span>
                    <span class="detail-row-text">{{ desc }}</span>
                  </div>
                </div>
              </div>
              <div class="promo-rule-item" v-if="promo.excludeItemIds && promo.excludeItemIds.length > 0">
                <div class="promo-rule-item_title">
                  <image class="promo-rule-item_title-icon" src="/static/images/guize.png" mode="widthFix" />
                  <span>适用范围：</span>
                </div>
                <div class="promo-rule-item_detail">
                  <div class="detail-row">
                    <span class="detail-row-icon">·</span>
                    <span class="detail-row-link" @click="itemPopupShow = true">{{ promo.excludeItemIds.length }}款指定商品</span>
                    <span class="detail-row-text">不可使用本券</span>
                  </div>
                </div>
              </div>
              <!-- 适用的就餐方式 -->
              <div class="promo-rule-item" v-if="promo.channel">
                <div class="promo-rule-item_title">
                  <image class="promo-rule-item_title-icon" src="/static/images/jiucan.png" mode="widthFix" />
                  <span>适用的就餐方式：</span>
                </div>
                <div class="promo-rule-item_detail">
                  <div class="detail-row">
                    <span class="detail-row-icon">·</span>
                    <span class="detail-row-text">{{ promo.channel | channelFilter }}</span>
                  </div>
                </div>
              </div>
              <div class="promo-rule-item">
                <div class="promo-rule-item_title">
                  <image class="promo-rule-item_title-icon" src="/static/images/guize.png" mode="widthFix" />
                  <span>使用规则</span>
                </div>
                <div class="promo-rule-item_detail">
                  <div class="detail-row" v-if="promo.promoSet === 38">
                    <span class="detail-row-icon">·</span>
                    <span class="detail-row-text">点击蓝色的券名称查看具体使用规则</span>
                  </div>
                  <div class="detail-row" v-if="promo.perDayTimes">
                    <span class="detail-row-icon">·</span>
                    <span class="detail-row-text">每人每天最多可使用{{ promo.perDayTimes }}张</span>
                  </div>
                  <div class="detail-row" v-if="promo.buyCouponNum">
                    <span class="detail-row-icon">·</span>
                    <span class="detail-row-text">每人限购{{ promo.buyCouponNum }}张</span>
                  </div>
                  <div class="detail-row" v-if="promo.maxDiscount">
                    <span class="detail-row-icon">·</span>
                    <span class="detail-row-text">最高可优惠{{ promo.maxDiscount }}元</span>
                  </div>
                  <div class="detail-row" v-if="promo.orderLimit">
                    <span class="detail-row-icon">·</span>
                    <span class="detail-row-text">消费满{{ promo.orderLimit }}元可用</span>
                  </div>
                  <div v-if="promo.salePrice" class="detail-row">
                    <span class="detail-row-icon">·</span>
                    <!-- 可在购买的7天后申请退券（有限制时才显示）。 -->
                    <span class="detail-row-text">
                      <template v-if="promo.refundDaysLimit">可在购买的{{ promo.refundDaysLimit }}天后申请退券，</template>
                      <template v-else>可随时申请退券，</template>
                      <template v-if="promo.promoSet === 38">券包退款金额=购券金额-券已优惠金额（已优惠金额包含已使用及已赠出的券）；</template>
                      <template v-else>退款金额=购券金额；</template>
                      可购买次数不予退回。可在《悠订》公众号的“我的”查看使用规则和申请退款。
                      <template v-if="promo.promoSet === 38 && promo.autoRefundStatus === 1">当券包中最后一张券过期时，自动退款。</template>
                      <template v-if="(promo.promoSet === 33 || promo.promoSet === 37) && promo.autoRefundStatus === 1">当优惠券过期时，自动退款。</template>
                      连续365天内累计退款超过3次的购券人，将在之后的365天内无法再次领取和购买本公司发行的任何预付卡和预付券
                    </span>
                  </div>
                  <template v-if="promo.promoSet === 38 && promo.presentStatus === 1">
                    <div class="detail-row">
                      <span class="detail-row-icon">·</span>
                      <span class="detail-row-text">券包内所有优惠券可转赠他人，受赠人不可申请退款。</span>
                    </div>
                  </template>
                  <div class="detail-row" v-else>
                    <span class="detail-row-icon">·</span>
                    <span v-if="promo.presentStatus === 1" class="detail-row-text">此券可转赠他人，受赠人不可申请退款。</span>
                    <span v-else class="detail-row-text">此券不可转赠他人</span>
                  </div>
                  <div class="detail-row">
                    <span class="detail-row-icon">·</span>
                    <span class="detail-row-text">不与门店其他活动折上折</span>
                  </div>
                  <div class="detail-row">
                    <span class="detail-row-icon">·</span>
                    <span class="detail-row-text">本公司旗下品牌发放的“所有会员卡和优惠券”，每日最多可在本司品牌下最多2家门店使用（当日已在2家店使用任意“本司的会员卡和优惠券”下单后，第3家店起无法再使用任何“本司的会员卡和优惠券”）；</span>
                  </div>
                  <div class="detail-row">
                    <span class="detail-row-icon">·</span>
                    <span class="detail-row-text">根据国家税务总局2016年第53号《关于营改增试点若干征管问题的公告》，向购卡人、充值人开具支付金额相等的“预付卡销售”增值税普通发票。</span>
                  </div>
                  <div class="detail-row" v-if="promo.ruleDesc">
                    <span class="detail-row-icon">·</span>
                    <span class="detail-row-text">{{ promo.ruleDesc }}</span>
                  </div>
                </div>
              </div>
              <!-- 适用的门店 -->
              <div class="promo-rule-item" v-if="promo.storeIds && promo.storeIds.length > 0">
                <div class="promo-rule-item_title">
                  <image class="promo-rule-item_title-icon" src="/static/images/shiyongmendian.png" mode="widthFix" />
                  <span>适用的门店：</span>
                </div>
                <div class="promo-rule-item_detail">
                  <div class="detail-row">
                    <span class="detail-row-icon">·</span>
                    <span class="detail-row-text">【{{ promo.brandName }}】</span>
                    <span class="detail-row-link" @click="storePopupShow = true">{{ promo.storeIds.length }}家门店</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </popup-box>
    <!-- loading提示 -->
    <loading-box :show="loadingShow" />
    <!-- 门店弹窗组件 -->
    <store-select-popup :show.sync="storePopupShow" :brandCode="promo.brandCode" :selectedIds="selectedStoreIds" title="此“优惠”适用的门店" :editable="false" />
    <!-- 商品弹窗组件 -->
    <item-select-popup :show.sync="itemPopupShow" :brandCode="promo.brandCode" :selectedIds="promo.excludeItemIds" :editable="false" />
  </div>
</template>

<script>
import LoadingBox from '@/components/common/LoadingBox'
import PopupBox from '@/components/common/PopupBox'
import StoreSelectPopup from '@/components/business/StoreSelectPopup'
import ItemSelectPopup from '@/components/business/ItemSelectPopup'
import { getPromoDetailApi } from '@/api/promo'
export default {
  name: 'StorePromoDetailPopup',
  components: {
    LoadingBox,
    PopupBox,
    StoreSelectPopup,
    ItemSelectPopup
  },
  props: {
    show: { type: Boolean, required: true, default: false },
    promoId: { type: String, required: true, default: '' },
    way: { type: Number, required: true, default: 3 },
    userId: { type: String, required: false, default: 'userId', desc: 'userId' }
  },
  data() {
    return {
      wayId: '',
      loadingShow: false,
      promo: {
        images: []
      },
      storePopupShow: false,
      itemPopupShow: false
    }
  },
  filters: {
    promoDescFilter(val) {
      // promoSet: 33现金券   34折扣券   37商品券   38券包
      if (!val) {
        return ''
      }
      // 剩下是商品券
      if (val.promoSet === 37 && val.couponItems && val.couponItems.length > 0) {
        // 套餐券
        if (val.couponItemUnitMenus && val.couponItemUnitMenus.length > 0) {
          const item = val.couponItems[0]
          return `凭券可${item.promoPrice}元购买原价${item.itemPrice}元的套餐`
        }
        // 单品券, 已有一个商品
        if (val.couponItems.length === 1) {
          const item = val.couponItems[0]
          return `特价${item.promoPrice}元购买原价${item.itemPrice}元的【${item.itemName}】`
        }
        // 单品券, 多个商品
        return `凭券可优惠价购买${val.couponItems.length}款商品中的任意${val.totalItemCount}款`
      }

      // 38是组合套餐券
      if (val.promoSet === 38 && val.couponPackages && val.couponPackages.length > 0) {
        let amountCouponNum = 0
        let discountCouponNum = 0
        let itemCouponNum = 0
        val.couponPackages.forEach((item) => {
          const count = item.count || 1
          if (item.promoSet === 33) {
            amountCouponNum += count
          } else if (item.promoSet === 34) {
            discountCouponNum += count
          } else {
            itemCouponNum += count
          }
        })
        let str = ''
        if (amountCouponNum > 0) {
          str += `${amountCouponNum}张现金抵用券、`
        }
        if (discountCouponNum > 0) {
          str += `${discountCouponNum}张折扣券、`
        }
        if (itemCouponNum > 0) {
          str += `${itemCouponNum}张商品券、`
        }
        if (str) {
          str = str.slice(0, str.length - 1)
          str = '内含' + str
          return str
        } else {
          return ''
        }
      }

      // 没有商品的，就是现金券
      if (val.promoSet === 33) {
        const orderLimit = val.orderLimit || 0
        let desc = `消费满${orderLimit}元立减${val.couponAmount}元`
        return desc
      }
      // 折扣券
      if (val.promoSet === 34) {
        let desc = `凭券可享指定商品${val.discountRate || 0}折`
        return desc
      }

      return ''
    },
    discountRateFilter(val) {
      if (!val.discountRate || val.promoSet === 34) {
        return ''
      }
      return (val.discountRate * 10).toFixed(1) + '折'
    },
    channelFilter(val) {
      if (!val) {
        return ''
      }
      const result = []
      if (val.indexOf('1') > -1) {
        result.push('堂食')
      }
      if (val.indexOf('2') > -1) {
        result.push('外带')
      }
      if (val.indexOf('4') > -1) {
        result.push('外卖')
      }
      if (result.length === 0) {
        return ''
      } else if (result.length === 1) {
        return '仅' + result[0]
      } else {
        return result.join('、') + '均可使用'
      }
    }
  },
  computed: {
    weekdayDesc() {
      return (val) => {
        if (!val) {
          return ''
        }
        if (val.indexOf('8') > -1) {
          return '全部法定工作日（包含有效期内因法定节假日调休上班的周六、日；不包含有效期内因法定节假日调休的工作日）'
        }
        const weekdayList = val.split(',').map((x) => parseInt(x))
        weekdayList.sort((a, b) => a - b)
        const len = weekdayList.length
        const weekdayDescList = ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
        const firstvalue = weekdayList[0]
        const lastValue = weekdayList[len - 1]
        // 如果只有一天，则直接输出
        if (len === 1) {
          return weekdayDescList[firstvalue - 1]
        }
        // 如果首尾天数差等于总天数，说明是连续的
        if (lastValue - firstvalue === len - 1) {
          return weekdayDescList[firstvalue - 1] + '至' + weekdayDescList[lastValue - 1]
        }
        // 不连续的则直接全部输出
        return weekdayList.map((x) => weekdayDescList[x - 1]).join('、')
      }
    },
    timeRangeDesc() {
      return (val) => {
        if (!val.fromTime || !val.toTime) {
          return ''
        }
        return val.fromTime + '-' + val.toTime
      }
    },

    buyTitle() {
      return this.promo.salePrice ? '购买' : '领取'
    },
    effectHourDesc() {
      if (!this.promo.couponEffectType) {
        return ''
      }
      if (this.promo.couponEffectType === 1) {
        return `${this.buyTitle}后次日生效`
      }
      if (this.promo.couponEffectType === 2 && this.promo.couponEffectHours) {
        return `${this.buyTitle}后${this.promo.couponEffectHours}小时生效`
      }
      return ''
    },
    selectedStoreIds() {
      if (!this.promo.stores) {
        return []
      }
      return this.promo.stores.map((x) => x.storeId)
    }
  },
  watch: {
    show(val, oldVal) {
      if (val) {
        this.loadingShow = true
        this.closePopup()
        this.getPromoDetail()
      }
    }
  },
  methods: {
    getPromoDetail() {
      getPromoDetailApi(this.promoId, this.userId, this.way, this.wayId).then((res) => {
        if (res.status !== '1') {
          return false
        }
        this.promo = res.data
        this.loadingShow = false
      })
    },
    transferImage(key) {
      return this.$transferQnyUrl(key, 800)
    },
    onClose() {
      this.closePopup()
      this.$emit('update:show', false)
      this.$emit('onClose')
    },
    closePopup() {
      this.storePopupShow = false
      this.itemPopupShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
.promo-image {
  position: relative;
  width: 100%;
  padding: 24px 24px 0 24px;
  background-color: $color-white;

  .swipe-box {
    position: relative;
    width: 100%;
    height: 350px;
    // height: 375px;
    .swipe-item-box {
      border-radius: 16px;
      .swipe-item {
        .swipe-item_img {
          width: 100%;
          border-radius: 16px;
        }
      }
    }

    /deep/ .van-swipe__indicator--active {
      background-color: $color-white;
    }
  }

  .promo-tag {
    position: absolute;
    bottom: 20px;
    left: 24px;

    .promo-tag_item {
      display: inline-block;
      min-width: 110px;
      height: 40px;
      padding: 0 8px;
      line-height: 40px;
      text-align: center;
      font-size: 24px;
      color: $color-white;
      background-color: $color-danger-main;
      border-radius: 20px;
      &:not(:first-child) {
        margin-left: 12px;
      }
    }
  }
}

.promo-info {
  width: 100%;
  padding: 24px;
  margin-bottom: 16px;
  background-color: $color-white;
  position: sticky;
  top: 0;

  .promo-info-name {
    line-height: 40px;
    color: $color-text-main;
    font-size: 32px;
    font-weight: bold;
  }

  .promo-info-desc {
    margin-top: 16px;
  }

  .promo-info-price {
    margin-top: 26px;
    line-height: 36px;
    color: $color-warning;
    font-size: 24px;
    display: flex;
    align-items: center;

    .promo-info-price_num {
      color: $color-warning;
      font-size: 30px;
      font-weight: bold;
    }

    .promo-info-price_discount {
      display: inline-block;
      padding: 4px 16px;
      margin-left: 16px;
      line-height: 24px;
      color: $color-warning;
      font-size: 20px;
      font-weight: bold;
      border: 1px solid $color-warning;
      border-radius: 8px;
    }
  }

  .promo-info-tag {
    margin-top: 18px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .promo-info-tag__item {
      margin-right: 16px;
      margin-bottom: 8px;
      padding: 5px 8px;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: $color-warning;
      border-radius: 8px;
      background-color: rgba($color-warning, 0.12);
    }
  }
}

.promo-item {
  width: 100%;
  padding: 24px;
  margin-bottom: 16px;
  background-color: $color-white;

  .promo-item-unit {
    width: 100%;

    .unit-category {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      .unit-category-name {
        width: 100%;
        line-height: 34px;

        .unit-category-name_text {
          color: $color-text-main;
          font-size: 28px;
          font-weight: bold;
        }

        .unit-category-name_count {
          margin-left: 8px;
          color: $color-text-main;
          font-size: 28px;
          font-weight: bold;
        }

        .unit-category-name_desc {
          margin-left: 8px;
          color: $color-text-sub;
          font-size: 28px;
        }
      }

      .unit-category-item {
        display: flex;
        width: 100%;
        margin-top: 16px;
        align-items: center;

        .unit-category-item_left {
          flex: 1;

          .item-tag {
            font-size: 30px;
            font-weight: bold;
          }

          .item-name {
            margin-left: 8px;
            line-height: 34px;
            color: $color-text-main;
            font-size: 24px;
          }

          .item-count {
            margin-left: 8px;
            line-height: 34px;
            color: $color-text-sub;
            font-size: 24px;
          }
        }

        .unit-category-item_right {
          flex: 0;
          white-space: nowrap;

          .item-price {
            margin-left: 16px;
            line-height: 34px;
            color: $color-text-main;
            font-size: 24px;
          }
        }
      }
    }
  }
}

.promo-rule {
  width: 100%;
  padding: 24px;
  background-color: $color-white;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .promo-rule-title {
    width: 100%;
    margin-bottom: 24px;
    line-height: 44px;
    color: $color-text-main;
    font-size: 32px;
    font-weight: bold;
  }

  .promo-rule-item {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    .promo-rule-item_title {
      width: 100%;
      line-height: 40px;
      color: $color-text-main;
      font-size: 28px;
      font-weight: bold;

      .promo-rule-item_title-icon {
        width: 40px;
        height: 40px;
        margin-right: 8px;
        vertical-align: bottom;
      }
    }

    .promo-rule-item_detail {
      .detail-row {
        width: 100%;
        margin-top: 8px;
        line-height: 40px;
        font-size: 28px;
        color: $color-text-main;

        .detail-row-icon {
          margin-right: 8px;
          font-size: 30px;
          font-weight: bold;
        }

        .detail-row-text {
          font-size: 28px;
          color: $color-text-main;
          font-family: PingFangSC-Regular, PingFang SC;
        }

        .detail-row-price {
          font-size: 28px;
          color: $color-danger;
        }

        .detail-row-link {
          font-size: 28px;
          color: $color-text-click;
        }
      }
    }
  }
}

.promo-rule-1 {
  width: 100%;
  padding: 24px;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: $color-white;

  .promo-rule-title {
    width: 100%;
    margin-bottom: 16px;
    line-height: 40px;
    color: $color-text-main;
    font-size: 32px;
    font-weight: bold;
  }

  .promo-rule-item {
    width: 100%;
    line-height: 42px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    .promo-rule-item_label {
      color: $color-text-main;
      font-size: 28px;
      font-weight: bold;
    }

    .promo-rule-item_content {
      color: $color-text-main;
      font-size: 28px;
    }

    .promo-rule-item_detail {
      width: 100%;
      max-height: 50px;
      transition: all 0.5s;
      overflow: hidden;

      &.more-show {
        max-height: 800px;
      }

      .detail-row {
        width: 100%;
        margin-top: 8px;
        color: $color-text-main;
        font-size: 28px;

        .detail-row_icon {
          margin-right: 8px;
          font-size: 30px;
          font-weight: bold;
        }
      }
    }

    .promo-rule-item_more {
      width: 100%;
      margin-top: 24px;
      line-height: 34px;
      text-align: center;
      color: $color-text-normal;
      font-size: 28px;

      .iconfont {
        margin-left: 8px;
        font-size: 28px;
      }
    }
  }
}
.right-slot-btn {
  display: flex;
  height: 72px;
  margin: 0 24px;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: bold;
  text-align: center;
  color: $color-text-normal;
  background: $color-background;
  border: 1px solid $color-text-sub;
  border-radius: 36px;
}
.card-agreement {
  padding: 24px;
  padding-top: 0;
  text-align: left;
  font-size: 26px;
}

.dialog-text {
  font-size: 30px;
  color: $color-text-normal;
  line-height: 40px;
  text-align: left;
}
.dialog-desc {
  font-size: 28px;
  color: $color-text-normal;
  line-height: 36px;
  text-align: left;
}
</style>
