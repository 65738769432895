<!--
 * @Author: hwu
 * @Date: 2022-01-29 16:17:47
 * @Description: 《用户服务协议》弹窗
 * @LastEditTime: 2024-05-27 11:49:11
-->

<template>
  <popup-box :show="show" position="bottom" showCloseButton :popupStyle="customPopupStyle" :maskStyle="maskStyle" @onClose="onClose()">
    <div class="wh-popup-container">
      <div class="wh-popup-header">
        <div class="wh-popup-header_title text-left">
          <span class="title-text">{{ companyName }}单用途预付卡券章程</span>
        </div>
      </div>
      <div class="wh-popup-body with-header">
        <div class="wh-popup-content">
          <div class="article-box">
            <div class="article-paragraph">
              <div class="article-paragraph-title">第一章、概述</div>
              <div class="article-paragraph-row">1.1、为规范{{ companyName }}单用途预付卡（即预付卡）经营活动，特制定本章程。本公司、购卡人及其它当事人进行与预付卡相关的活动，适用本章程，包括但不限于本公司对预付卡的发行、出售、兑付、退换及注销，购卡人对预付卡的购买、使用、转让、挂失及补换等。</div>
              <div class="article-paragraph-row">1.2、本章程涉及的部分名词遵从如下定义:</div>
              <div class="article-paragraph-row">“发卡人”:指{{ companyName }}，也称“本公司”，已经取得了上海市商务委员会单用途商业预付卡备案资质(备案企业编码: {{ code }} ;发卡企业类型:品牌发卡)。</div>
              <div class="article-paragraph-row">“购卡人”:指符合本章程之规定购买预付卡的单位或个人。</div>
              <div class="article-paragraph-row">“持卡人”:指预付卡的合法实际持有人。</div>
              <div class="article-paragraph-row">“售卡人”:指发卡人或经发卡人授权可合法出售{{ brandName }}单用途商业预付卡的单位或个人。</div>
              <div class="article-paragraph-row">“{{ brandName }}品牌店铺”:也称{{ brandName }}特约商户或特约商户，指发卡人在其官方网站、悠订平台、社交媒体官方账号等不时公布的，在中华人民共和国境内(不含港澳台地区)可受理特 定或全部{{ brandName }}单用途商业预付卡的店铺，包括线下店铺和网络店铺。</div>
              <div class="article-paragraph-row">“悠订平台”:可以用于购买、激活、使用及管理{{ brandName }}单用途商业预付卡的软件程序，包括但不限于悠订小程序(已上线)、企业悠订APP(开发中)。</div>
              <div class="article-paragraph-row">{{ brandName }}公司提醒您，因业务繁忙或促销活动等原因，并非所有特约商户在所有时间段内均可以受理{{ brandName }}单用途商业预付卡，需以使用时特约商户公布或告知的具体情形为准。</div>
              <div class="article-paragraph-row">1.3、发卡人、购卡人、持卡人、售卡人、特约商户均应遵守本章程之规定，并受本章程之约束。</div>
            </div>
            <div class="article-paragraph">
              <div class="article-paragraph-title">第二章、{{ brandName }}单用途商业预付卡</div>
              <div class="article-paragraph-row">
                2.1、{{ brandName }}单用途商业预付卡包括以下各种券卡:(1)电子预付卡:由发卡人发行并经购买人实际出资向售卡人购买的以密码 (或串码等其它形式)作为载体并经悠订平台按照激活规则进行激活、兑换或直接通过悠订平台开通的虚拟卡，具有支付功能，可在特约商户购买其出售的指定商品。(2) 电子预付券:由发卡人发行并经购买人实际出资向售卡人购买的以密码(或串码等其
                它形式)作为载体并经悠订平台兑换或直接发放在悠订平台的电子券，使用该券可按照规则兑换相应商品。(3)发卡人依法发行的其他单用途商业预付卡券。 上述预付卡券在本章程中合称为“预付卡”。
              </div>
              <div class="article-paragraph-row">2.2、单张预付卡以人民币计价，面值不超过人民币 5,000 元。</div>
              <div class="article-paragraph-row">2.3、<span class="text-strong">预付卡为记名卡，可挂失、转让</span>，但不用于兑换或提取现金、不具有透支功能。卡内资金不 产生利息、红利或任何其他收益，且不可向银行账户或网络支付账户进行转账或转移。</div>
              <div class="article-paragraph-row">2.4、发卡人有权对预付卡的使用范围、方式或条件等作出补充规定并通过预付卡本身(例如卡面、券面或电子通知等方式)或《权益规则详情》向购卡人/持卡人作出说明，购卡人及持卡人亦应遵守该规定。</div>
            </div>
            <div class="article-paragraph">
              <div class="article-paragraph-title">第三章、预付卡的购买、充值</div>
              <div class="article-paragraph-row">
                3.1、凡自愿遵守本章程，具有完全民事行为能力(无民事行为能力或限制民事行为能力的自然人需事先取得监护人同意)的人，均可通过发卡人指定的渠道购买预付卡。 购卡人需设定交易密码并绑定一个手机号。购卡人/持卡人并应妥善保管好交易密码，购卡人/持卡人不应将交易密码告知他人或提供他人使用，否则由于交易密码泄露造成的一切损失均由购卡人/持卡人自行承担。
              </div>
              <div class="article-paragraph-row">3.2、持卡人可以现金、借记卡、信用卡或其他发卡人或售卡人接受的支付方式，通过售卡人或发卡人不时指定的其他渠道为预付卡进行充值。可充值的预付卡种类以及可办理充值的渠道与所在区域由发卡人另行通知。</div>
              <div class="article-paragraph-row">3.3、单张电子预付卡/券累计余额不得超过 5,000 元。</div>
              <div class="article-paragraph-row">
                3.4、持卡人进行充值时如发卡人/售卡人提供充赠活动的，预付卡的充值金额(实付金额) 和赠送金额将在预付卡后台进行区分管理、核算，且使用预付卡进行支付时会优先使用充值金额，充值金额余额不足时才开始使用赠送金额进行支付。<span class="text-strong">充值金额不设有效期即长期有效，赠送金额设有有效期，详见《权益规则详情》。</span>
              </div>
              <div class="article-paragraph-row">3.5、持卡人每次充值时根据预付卡种类及支持预付卡运营的系统不同，可选择的充值金额由发卡人确定并显示在相关充值页面，该情况下持卡人自行选择充值页面的金额进行充值。</div>
              <div class="article-paragraph-row">3.6、实名认证</div>
              <div class="article-paragraph-row">3.6.1、如购卡人一次性购卡或持卡人一次性充值金额在 1 万元(含)以上的，应向发卡人、指定的售卡人购买或致电发卡人客服电话由专人协助购买，并进行实名认证。</div>
              <div class="article-paragraph-row">
                3.6.2、为办理实名认证，购卡人/持卡人应提供有效的身份证件，包括但不限于购卡人/持卡人及其代理人姓名或单位名称、有效身份证件号码和联系方式，供发卡人或经其书面授权的售卡人留存。个人有效身份证件包括居民身份证、户口簿、军人身份证件、武警身份证件、港澳台居民通行证、护照等。单位有效身份证件包括营业执照、事业单位法人证书等。
              </div>
              <div class="article-paragraph-row">3.6.3、发卡人及售卡人应保存前述购卡人/持卡人的登记信息 5 年以上。</div>
              <div class="article-paragraph-row">3.7、单位一次性购卡金额达 5000 元(含)以上或个人一次性购卡或充值金额达 5 万元 (含)以上的，以及单位或个人采用非现场方式购卡或充值的，不可使用现金，应通过银行向发卡人或售卡人指定银行账户转账。购卡/充值单位或个人应提供银行付款账户的信息 (包括:账户名称、账号及金额等)。</div>
              <div class="article-paragraph-row">3.8、应发卡人/售卡人的要求，购卡人应与发卡人/售卡人签订预付卡购买合同。若购卡人要求，发卡人/售卡人亦应与购卡人签订预付卡购买合同。</div>
            </div>
            <div class="article-paragraph">
              <div class="article-paragraph-title">第四章、预付卡的使用和管理(含兑换、赠送、终止兑付等)</div>
              <div class="article-paragraph-row">4.1、在持卡人持预付卡在特约商户购买商品或兑换指定产品时，若预付卡余额不足的，持卡人可尝试与第三方支付或其它预付卡组合支付;当预付卡余额不足且无法与第三方支付及其它预付卡组合支付的，持卡人可向悠订平台客服咨询其它解决方案或建议。</div>
              <div class="article-paragraph-row">4.2、部分购卡人购买的电子预付卡也可以通过一定的流程赠送给他人，具体以发卡人另行通知为准。</div>
              <div class="article-paragraph-row">4.3、预付卡购买后即可使用。</div>
              <div class="article-paragraph-row">4.4、预付卡交易以联网的方式自动化进行实时支付和结算;交易完成时，所购商品的对应金额或电子预付券即时从预付卡账户中扣除。交易中打印的销售小票是该项交易的有效凭据。</div>
              <div class="article-paragraph-row">4.5、除发卡人或特约商户另有规定外，使用预付卡时不可再享受特约商户的其他折扣或优惠。</div>
              <div class="article-paragraph-row">4.6、<span class="text-strong">为避免刷单特约商家会设置购卡后0-30天后购卡人可申请退卡，可退卡日期根据《权益规则详情》为准。连续365天内累计退卡超过3次的购卡人，将在之后的365天内无法再次领取和购买本公司发行的任何预付卡和预付券。</span></div>
              <div class="article-paragraph-row">4.7、交易错误的查询与更正</div>
              <div class="article-paragraph-row">4.7.1、如发卡人在对账过程中发现交易中存在记账或其他交易差错，发卡人有权更正预付卡内余额。如对该等更正有疑义，持卡人可联系悠订平台客服。发卡人将对持卡人的疑义进行调查，更正差错(如有)并及时告知持卡人处理结果。如不存在差错，发卡人将对持卡人作出相应解释。</div>
              <div class="article-paragraph-row">4.7.2、如持卡人认为交易过程中存在记账或其他交易差错，持卡人应在交易发生后<span class="text-strong">30日内</span>联系悠订平台客服或以其它有效方式联系发卡人;经发卡人证实确有差错的，发卡人应更正预付卡内的余额或退还电子预付券。</div>
              <div class="article-paragraph-row">4.8、发卡人将向持卡人提供预付卡资金余额查询，向持卡人就预付卡使用的相关事项提供咨询，并处理持卡人的投诉。</div>
              <div class="article-paragraph-row">4.9、购卡人和/或持卡人不应将预付卡以任何形式转售予任何第三方，也不应将预付卡用于任何非法目的。</div>
              <div class="article-paragraph-row">4.10、持卡人使用预付卡进行支付时如需要交易密码的，应自行输入交易密码，不能将交易密码告知他人，并确保预付卡支付的使用安全，防止被他人窃取。另外，持卡人应定期查杀木马、病毒等，防止因登陆环境的不安全，导致预付卡信息及(或)交易密码的泄露。</div>
              <div class="article-paragraph-row">4.12、对于因持卡人连续多次输入错误交易密码导致预付卡交易被锁定的，预付卡当日无法正常使用，持卡人需在次日输入正确交易密码后解锁后方可正常使用。若需当日解锁账户， 持卡人应与悠订平台客服联系解锁事宜。</div>
              <div class="article-paragraph-row">4.13、发卡人若终止兑付未到期预付卡余额的，会向持卡人提供免费退卡服务，并在终止兑付前 30 日在备案机关指定的媒体上进行公示。<span class="text-strong">退卡规则为仅退还剩余的充值金额，不退还赠送金额，</span>因此建议持卡人在退卡前将预付卡内的赠送金额尽量使用完毕。</div>
              <div class="article-paragraph-row">4.14、购卡人可将未使用的预付卡转赠给受赠人，受赠人需要24小时内领取，超时将退回购卡人。受赠人领取预付卡后，不可开具获赠预付卡的发票和申请退获赠的预付卡。购卡人赠送出的预付卡中赠送金额的有效期不会因领取后重置。</div>
              <div class="article-paragraph-row">4.15、预付卡仅供购卡人/持卡人在本章程规定的条件下使用，持有或使用预付卡不代表购卡人/持卡人获得授权使用“{{ brandName }}”以及其他由发卡人或其关联公司拥有的标识、商标或任何其他与之相关的知识产权。</div>
            </div>
            <div class="article-paragraph">
              <div class="article-paragraph-title">第五章、退货、退卡与遗失</div>
              <div class="article-paragraph-row">5.1、退货</div>
              <div class="article-paragraph-row">
                5.1.1、除本章程第 5.1.2 款规定的情形外，若持卡人要求退掉持预付卡购买的商品且该等退货符合特约商户相关退货规定的，购买该商品时从该预付卡中支出的任何金额将被退至该 预付卡内;原预付卡的有效期不因退货而改变。如 原预付卡不存在，退货金额不足人民币 100 元(含)的，可支付现金;退货金额超过人民币 100 元的，就人民币 100 元整数倍 的部分，发卡人/特定商
                户将给予持卡人一张或多张新的预付卡，不足人民币 100 元的尾数部分，可支付现金，或银行转账方式退还至原购卡银行账户，或购卡人提供的与购卡人名下的其他银行账户中。在此退货情形下，发卡人/特约商户有权要求持卡人出示本章程第3.7 款规定的身份证件。
              </div>
              <div class="article-paragraph-row">5.1.2、若持卡人要求退掉以预付券兑换的商品且该等退货符合特约商户相关退货规定的， 持卡人将得到与退货商品等值的、可用于下次消费的凭证。</div>
              <div class="article-paragraph-row">5.2、退卡</div>
              <div class="article-paragraph-row">
                5.2.1、若持卡人要求退卡的，退卡人应是购买预付卡的原购买人，并应在发卡人指定的渠道办理退卡。办理退卡时，个人退卡人应提供与原购卡人一致的有效身份证件，企业退卡人应提供加盖公章的退卡申请和营业执照复印件。预付卡退卡人应退还购卡的原始发票、充值发票(如适用)以及相关的付款凭证。
                如预付卡退卡人要求退还其在购买时享受过任何折扣或优惠的预付卡，在计算退款时将按退卡人在购买（及充值时）时实际支付的金额或按预付卡全额扣除上述优惠后的金额办理退款。如预付卡退卡人购买预付卡可按其年度累计购卡金额享有任何折扣或优惠的，在办理退卡后会将退卡金额从其年度累计购卡金额中扣除。
              </div>
              <div class="article-paragraph-row">5.3、自发卡人或售卡人将预付卡交付给购卡人之时起(发卡人通过电子方式发送给购卡人即视为交付)，预付卡的丢失、毁损等一切相关风险转移予购卡人。购卡人/持卡人未妥善保管预付卡导致预付卡遗失、灭失、被他人冒用的，应当自行承担损失。</div>
            </div>
            <div class="article-paragraph">
              <div class="article-paragraph-title">第六章、发票</div>
              <div class="article-paragraph-row">6.1、就购卡人购买的预付卡或持卡人充值，发卡人将根据本章程约定的程序， 向购卡人或持卡人开具符合国家适用法律、法规规定的发票。对于持卡人根据本章程第 5.1.1 项退货取得的新预付卡，发卡人不再开具发票。</div>
              <div class="article-paragraph-row">6.2、发票的申请与开具</div>
              <div class="article-paragraph-row">6.2.1、购卡人/持卡人在售卡人处购买预付卡或充值后，在悠订平台按照提示和指引自助开具发票(如遇其它开票问题可联系客服解决)。</div>
              <div class="article-paragraph-row">6.2.2、购卡人/持卡人所申请的发票金额需与销售或充值小票上的购卡金额一致;所申请的发票内容需符合国家适用法律、法规，不可依购卡人/持卡人任意的要求而开具。</div>
              <div class="article-paragraph-row">6.3、持卡人持预付卡在特约商户购买或兑换商品时，特约商户将不再就该商品开具发票; 但同一笔交易中，若持卡人同时使用预付卡之外的付款方式进行付款，则特约商户可就预付卡之外的付款方式支付的款项根据国家适用法律、法规和特约商户的财务政策开具发票。</div>
            </div>
            <div class="article-paragraph">
              <div class="article-paragraph-title">第七章、免责声明</div>
              <div class="article-paragraph-row">
                7.1、持卡人同意，发卡人不对因下述任一情况而发生的服务中断或终止而承担任何损害赔偿责任，但发卡人应当协助持卡人解决问题并提供必要的帮助: (1)持卡人违反本服务条款的规定，将中断或终止向持卡人提供部分或全部服务功能。(2) 发卡人在发现持卡人异常交易或有疑义或有违法嫌疑时，不经通知有权先行中断或终止持卡 人交易，并拒绝持卡人使用预付卡部分或全部功能。
                (3)系统因下列状况无法正常运作，使持卡人无法使用相关服务，包括但不限于: A、该预付卡服务所需的软件程序、发卡人交易系统停机维修/维护期间; B、电力、电信或银行的系统、设备或网站发生故障、不稳定或升级维护，导致停电、或数据传输不稳定、失败或中断; C、因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素， 造成系统障碍 不能执行业务的;
                D、由于黑客攻击、电信部门技术调整或故障、网站升级、银行方面的问题等 原因而造成的服务中断或者延迟; E、司法机关或行政机关依照法定程序要求发卡人中断对持卡人服务的; F、为公共利益的需要必须中断对持卡人服务的;G、其他必须中断服务的情况。 (4)持卡人通过非发卡人的官方或授权途径购买预付卡的，发卡人有权中断 或终止向持卡人提供部分或全部服务。
              </div>
              <div class="article-paragraph-row">7.2、本条之规定不排除或限制发卡人、售卡人及特约商户在如下方面的责任: (1)欺诈行为引起的责任;(2)适用法律规定不应排除或限制的责任。</div>
            </div>
            <div class="article-paragraph">
              <div class="article-paragraph-title">第八章、争议解决</div>
              <div class="article-paragraph-row">8.1、任何由本章程引起的争议，双方应协商解决。若自一方书面通知另一方要求协商之日起 30 日内未能解决，则该等争议应向发卡人所在地人民法院提起诉讼。</div>
              <div class="article-paragraph-row">8.2、在争议解决过程中，除正在进行诉讼的部分外，本章程的其他部分仍应继续执行。</div>
            </div>
            <div class="article-paragraph">
              <div class="article-paragraph-title">第九章、附则</div>
              <div class="article-paragraph-row">9.1、发卡人有权对预付卡的使用范围、方式或条件等作出补充规定并通过官方网站、悠订平台及社交媒体官方账号等的一种或者多种途径作出说明。</div>
              <div class="article-paragraph-row">9.2、本章程由发卡人制订、修改、解释。发卡人有权根据需要修改本章程，但将采取适当的形式在悠订平台或发卡人指定的其他途径公告。本章程经修改或调整公布后即生效，不再逐一通知开卡或持卡当事人。修改后的条款对所有当事人具有同等约束力。</div>
              <div class="article-paragraph-row">9.3、本章程未尽事宜按照国家有关法律、法规、规章及行业惯例执行，本公司与购卡人另有约定的，遵从其约定。</div>
              <div class="article-paragraph-row">9.4、本章程自发布日2022年6月9日起生效，自被修订日或废止日失效。</div>
            </div>
            <div class="article-paragraph">
              <div class="article-paragraph-row"><span class="text-strong">本协议未尽事宜参照《悠订系统使用协议》、《隐私政策》及商家的《权益规则详情》执行。</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部栏 -->
  </popup-box>
</template>
<script>
import PopupBox from '@/components/common/PopupBox'
export default {
  name: 'prepaid-charter-popup',
  components: { PopupBox },
  props: {
    show: { type: Boolean, required: true, default: false },
    companyName: { type: String, required: true, default: '' },
    brandName: { type: String, required: true, default: '' },
    code: { type: String, required: true, default: ' ' },
    popupStyle: { type: String, required: false, default: '' },
    maskStyle: { type: String, required: false, default: '' }
  },
  data() {
    return {}
  },
  computed: {
    customPopupStyle() {
      return `height:80vh;${this.popupStyle}`
    }
  },
  methods: {
    onClose(e) {
      this.$emit('update:show', false)
      this.$emit('onClose', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.article-box {
  width: 100%;
  padding: 24px;
  .article-title {
    width: 100%;
    margin-bottom: 24px;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: $color-text-main;
  }
  .article-date {
    width: 100%;
    margin-bottom: 16px;
    font-size: 28px;
    color: $color-text-main;
  }
  .article-paragraph {
    width: 100%;
    line-height: 48px;
    .article-paragraph-title {
      width: 100%;
      margin-bottom: 16px;
      font-size: 26px;
      font-weight: bold;
      color: $color-text-main;
    }
    .article-paragraph-row {
      width: 100%;
      padding: 0 24px;
      margin-bottom: 16px;
      font-size: 24px;
      color: $color-text-main;
      .text-strong {
        font-weight: bold;
        color: $color-text-main;
      }
    }
  }
}
</style>
