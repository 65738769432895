<!--
 * @Author: hwu
 * @Date: 2022-01-26 16:22:04
 * @Description: 购买会员卡 -> 会员卡详情
 * @LastEditTime: 2024-06-04 17:09:28
-->

<template>
  <div class="app-container">
    <div class="page-content">
      <div class="buy-content">
        <!-- 卡详情内容区 -->
        <card-detail-content ref="CardDetailContent" :cardId="cardId" :way="way" :wayId="wayId" :storeId="storeId" :userId="userId" withHeader withLoading @getCardInfo="getCardInfo">
          <template #header-extra>
            <card-buy-price-box ref="CardBuyPriceBox" :cardInfo="cardInfo" v-if="cardInfo.salePrices" />
          </template>
        </card-detail-content>

        <!-- 隐私协议 -->
        <div class="card-agreement">
          <span>购买前阅读</span>
          <span class="color-text-click" @click="agreePopupShow = true">《用户服务协议》</span>
          <template v-if="isValueCard && cardInfo.credentialUniqueNo">
            <span>、</span>
            <span class="color-text-click" @click="prepaidPopupShow = true">《预付卡券章程》</span>
          </template>
          <span>以及《权益规则详情》</span>
        </div>

        <!-- 针对苹果手机底部留白 -->
        <div class="wh-safe-area-padding"></div>
      </div>

      <!-- 底部栏 -->
      <btn-footer showLeftBtn leftBtnText="返回" rightSlot @onLeftClick="closePage">
        <button class="right-slot-btn" :class="{ disabled: cardInfo.status !== 1 }" @click="submitForm()">
          <template v-if="cardInfo.status === 0 && cardInfo.nextTime">
            <span class="btn-item-label">{{ cardInfo | nextTimeFilter }}</span>
          </template>
          <template v-else>
            <span class="btn-item-label">收款</span>
          </template>
        </button>
      </btn-footer>
    </div>
    <!-- 用户服务协议 -->
    <member-service-agree-popup :show.sync="agreePopupShow" />
    <!-- 预付费章程 -->
    <prepaid-charter-popup :show.sync="prepaidPopupShow" :companyName="cardInfo.credentialCompanyName" :brandName="cardBrandNames" :code="cardInfo.credentialUniqueNo" />
    <!-- 微信扫码付款弹窗 -->
    <dialog-box class="custom-scan-pay-box" :show="customScanPayDialogShow" title="请顾客用【微信】扫码付款" showLeftBtn leftBtnText="返回" rightBtnText="支付完成" @onLeftClick="customScanPayDialogShow = false" @onRightClick="closePage">
      <div slot="body">
        <vue-qr class="qrCode" :text="qrCodeUrl" :margin="0" colorDark="#000" colorLight="#fff" :logoScale="0.3" :size="200"></vue-qr>
        <p class="qrCode-amount">订单金额：￥{{ needPayAmount }}</p>
      </div>
    </dialog-box>
  </div>
</template>
<script>
import vueQr from 'vue-qr'
import BtnFooter from '@/components/common/BtnFooter'
import DialogBox from '@/components/common/DialogBox'
import CardDetailContent from '@/components/business/card/CardDetailContent'
import CardBuyPriceBox from '@/components/business/card/CardBuyPriceBox'
import MemberServiceAgreePopup from '@/components/business/MemberServiceAgreePopup'
import PrepaidCharterPopup from '@/components/business/PrepaidCharterPopup'
import { saveCardPaymentParamApi } from '@/api/pay'
import { generateWxProgramLinkApi } from '@/api/wechat'

export default {
  components: { vueQr, BtnFooter, DialogBox, CardDetailContent, CardBuyPriceBox, MemberServiceAgreePopup, PrepaidCharterPopup },
  data() {
    return {
      cardId: '',
      userId: '',
      way: 13,
      wayId: '',
      storeId: '',
      cardInfo: {
        marketingRule: {}
      },
      unableReason: '',
      isRegistered: false,
      mobile: '',
      agreePopupShow: false,
      prepaidPopupShow: false,
      selectedSaleList: [],
      submitDisabled: false,
      qrCodeUrl: '',
      needPayAmount: '',
      customScanPayDialogShow: false,
      successDialogShow: false
    }
  },
  filters: {
    nextTimeFilter(val) {
      // 即将开卖
      let monthStr = val.nextTime.substring(5, 7)
      let dayStr = val.nextTime.substring(8, 10)
      let timeStr = val.nextTime.substring(11, 16)
      return `${Number(monthStr)}月${Number(dayStr)}日${timeStr}开售`
    }
  },
  computed: {
    isValueCard() {
      return this.cardInfo?.cardType === '6'
    },
    cardBrandNames() {
      if (!this.cardInfo || !this.cardInfo.brands) {
        return ''
      }
      return this.cardInfo.brands.map((x) => x.brandName).join('、')
    }
  },
  created() {
    this.cardId = this.$route.query.cardId || ''
    this.userId = this.$route.query.userId || ''
    this.storeId = this.$route.query.storeId || ''
    if (!this.cardId) {
      this.$toast('参数错误')
      this.closePage()
    }
  },
  methods: {
    getCardInfo(val) {
      this.cardInfo = val
      this.loadingShow = false
    },
    closePage() {
      this.$bappSdk.closeWebView()
    },
    submitForm() {
      if (this.cardInfo.status !== 1) {
        this.$toast(this.unableReason)
        return false
      }

      if (this.submitDisabled) {
        return false
      }

      // 从价格选择组件，获取选中的价格
      const priceList = this.$refs.CardBuyPriceBox.getPriceFormParam()
      // 表单校验在组件里面，如果校验不通过，组件里面直接toast了
      if (!priceList) {
        return false
      }
      this.needPayAmount = priceList
        .reduce((result, item) => {
          if (item.storeValueAmount) {
            return (result += item.storeValueAmount * item.qty)
          }
          return (result += item.price * item.qty)
        }, 0)
        .toFixed(2)
      this.submitDisabled = true

      const paramVo = {
        cardId: this.cardId,
        sales: priceList,
        storeId: this.storeId,
        way: this.way,
        wayId: this.wayId,
        waiterId: this.userId
      }
      saveCardPaymentParamApi(paramVo).then((res) => {
        this.submitDisabled = false
        if (res.status !== 0) {
          this.$toast('提交失败')
          return false
        }
        this.generateCustomPayQrCodeUrl(res.data)
      })
    },
    generateCustomPayQrCodeUrl(logId) {
      const programQuery = `logId=${logId}`
      const programPath = 'packages/promo/pages/CardBuy'
      const param = {
        brandCode: this.brandCode,
        isExpire: 0,
        expireType: 1,
        expireInterval: 1,
        path: programPath,
        query: programQuery
      }
      generateWxProgramLinkApi(param).then((res) => {
        if (res.status !== 0) {
          this.$toast('生成小程序链接失败')
          this.qrCodeUrl = ''
          return false
        }
        this.qrCodeUrl = res.data
        this.customScanPayDialogShow = true
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.page-content {
  width: 100%;
  height: 100vh;
  padding-bottom: 100px;
  overflow-y: auto;
}

.card-agreement {
  width: 100%;
  padding: 24px;
  text-align: left;
  font-size: 26px;
}

.right-slot-btn {
  width: 100%;
  height: 80px;
  line-height: 72px;
  color: $color-white;
  background-color: $color-primary;
  border: 4px solid $color-primary;
  border-radius: 8px;
  .btn-item-desc {
    font-size: 24px;
  }
  .btn-item-label {
    font-size: 30px;
  }
  &.disabled {
    opacity: 0.5;
  }
}
.custom-scan-pay-box {
  img {
    width: 280px;
    height: 280px;
    background-color: #fff;
    padding: 8px;
    box-sizing: border-box;
  }
  .qrCode-amount {
    color: #fd7022;
    font-size: 30px;
    font-weight: bold;
  }
}
</style>
