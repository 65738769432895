<!--
 * @Author: hwu
 * @Date: 2022-05-30 18:44:24
 * @Description: file content
 * @LastEditTime: 2024-05-27 23:02:51
-->
<template>
  <div class="price-wrapper">
    <!-- 折扣卡, 单选 -->
    <template v-if="!isValueCard">
      <div class="price-row" v-for="(price, priceIndex) in cardInfo.salePrices" :key="priceIndex" @click="checkDiscountSalePrice(price.id)">
        <div class="price-row-label">{{ cardInfo.cardName }} · {{ price.validDays }}天 / {{ price.price | priceAmountFilter }}</div>
        <div class="price-row_icon" :class="{ active: price.id === selectedSaleId }">
          <span class="iconfont" :class="[price.id === selectedSaleId ? ' iconxuanzhong' : ' iconweixuanzhong']"></span>
        </div>
      </div>
    </template>
    <!-- 储值卡, 多选 -->
    <template v-else>
      <div class="prices-option-wrapper" v-if="valueCardPriceOptions.length > 1">
        <div class="prices-option-list">
          <div class="prices-option" :class="{ active: option.selected }" v-for="(option, index) in valueCardPriceOptions" :key="index" @click="checkValuePriceOption(option)">
            <template v-if="option.isCustom">
              <div class="prices-option-name">自定义金额</div>
            </template>
            <template v-else>
              <div class="prices-option-name">￥{{ option.price }}</div>
              <div class="prices-option-desc" v-if="salePresentDesc(option, false)">{{ salePresentDesc(option, false) }}</div>
            </template>
          </div>
        </div>
      </div>
      <div class="price-row" v-for="(price, priceIndex) in selectedPriceOptions" :key="priceIndex">
        <!-- 固定金额 -->
        <div class="price-row-label" v-if="!price.isCustom">
          <span class="price-row-label_name">储值{{ price.price }}元</span>
          <span class="price-row-label_desc" v-if="salePresentDesc(price, true)">{{ salePresentDesc(price, true) }}</span>
        </div>
        <!-- 自定义金额 -->
        <div class="price-row-label" v-else>
          <span class="price-row-label_name">自定义金额</span>
          <input class="price-row-label_input" type="digit" v-model="price.storeValueAmount" @input="storeValueAmountInput($event, price)" :placeholder="customAmountPlaceholder" placeholder-class="price-input-placeholder" />
        </div>
        <div class="price-row-btn">
          <div class="item-btn minus-btn" :class="{ disabled: price.qty === 1 }" v-if="price.qty > 0" @click.stop="minusQty(price)">
            <span class="item-btn_icon iconfont iconjianshao"></span>
            <span class="item-btn_overlay"></span>
          </div>
          <div class="item-qty" v-if="price.qty > 0">{{ price.qty }}</div>
          <div class="item-btn add-btn" :class="{ disabled: addBtnDisabled }" @click.stop="addQty(price)">
            <span class="item-btn_icon iconfont iconzengjia-xiankuang"></span>
            <span class="item-btn_overlay"></span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'card-detail-price-box',
  props: {
    cardInfo: { type: Object, require: true, default: () => {} }
  },
  data() {
    return {
      selectedSaleId: '',
      valueCardPriceOptions: [],
      selectedPriceOptions: []
    }
  },
  filters: {
    priceAmountFilter(val) {
      if (!val) {
        return '￥0.00'
      }
      return `￥${val}`
    }
  },
  computed: {
    isValueCard() {
      return this.cardInfo?.cardType === '6'
    },
    buyLimit() {
      console.log(this.cardInfo)
      return 0
    },
    salePresentDesc() {
      return (sale, withCount) => {
        let presentList = []
        if (sale.rechargeAmount) {
          const rechargeDesc = withCount ? `${sale.rechargeAmount}元` : `￥${sale.rechargeAmount}`
          presentList.push(rechargeDesc)
        }
        if (sale.coupons && sale.coupons.length) {
          const qty = sale.coupons.reduce((result, item) => {
            return (result += item.quantity)
          }, 0)
          presentList.push(`${withCount ? qty + '张' : ''}优惠券`)
        }
        if (presentList.length) {
          return `${withCount ? '赠送' : '送'}${presentList.join('及')}`
        }
        return ''
      }
    },
    customAmountPlaceholder() {
      if (!this.cardInfo.marketingRule?.rechargeMinAmount) {
        return '请填写储值金额'
      }
      return `可储值${this.cardInfo.marketingRule.rechargeMinAmount}-${this.cardInfo.marketingRule.rechargeMaxAmount}`
    },
    addBtnDisabled() {
      if (!this.isValueCard) {
        return false
      }
      if (!this.buyLimit) {
        return false
      }
      let totalQty = 0
      this.valueCardPriceOptions.forEach((x) => {
        totalQty += x.qty || 0
      })
      if (totalQty >= this.buyLimit) {
        return true
      }
      return false
    }
  },
  created() {
    this.initPriceList()
  },
  methods: {
    initPriceList() {
      if (this.isValueCard) {
        this.initValueCardPriceList()
      } else {
        this.initDiscountCardPriceList()
      }
    },
    initDiscountCardPriceList() {
      if (!this.cardInfo.salePrices) {
        return
      }
      // 只有一个档位，默认选中
      if (this.cardInfo.salePrices.length === 1) {
        this.selectedSaleId = this.cardInfo.salePrices[0].id
      }
    },
    initValueCardPriceList() {
      let priceOptions = []
      const { isFixedAmount, rechargeMaxAmount } = this.cardInfo.marketingRule || {}
      const customAmount = {
        id: 999,
        isCustom: true,
        storeValueAmount: '',
        qty: 0
      }
      if (!isFixedAmount) {
        // 如果是自定义金额的，则只有自定义选项
        priceOptions = [customAmount]
      } else {
        // 如果是固定金额的，则每个档位是一个选项
        priceOptions = [...this.cardInfo.salePrices]
        // 如果是固定金额，但又有rechargeMaxAmount，说明勾选了自定义金额，需要再增加一个自定义选项
        if (rechargeMaxAmount) {
          priceOptions.push(customAmount)
        }
      }
      if (!priceOptions) {
        return false
      }
      this.valueCardPriceOptions = priceOptions
      this.selectedPriceOptions = []
      // 如果只有一个选项，则默认选中
      if (this.valueCardPriceOptions.length === 1) {
        const option = this.valueCardPriceOptions[0]
        option.qty = 1
        this.selectedPriceOptions = [option]
      }
    },
    checkDiscountSalePrice(id) {
      this.selectedSaleId = id
    },
    checkValuePriceOption(option) {
      option.selected = !option.selected
      // 为了保持排序，直接重新过滤选中的价格
      this.selectedPriceOptions = this.valueCardPriceOptions
        .filter((x) => x.selected)
        .map((y) => {
          // 看看选中的之前是否已经选过，如果已经选过，同步数量
          const oldSelectedOption = this.selectedPriceOptions.find((z) => z.id === y.id)
          if (oldSelectedOption) {
            y.qty = oldSelectedOption.qty
            y.storeValueAmount = oldSelectedOption.storeValueAmount
          } else {
            y.qty = 1
          }
          return y
        })
    },
    minusQty(price) {
      if (price.qty <= 1) {
        price.qty = 1
        return
      }
      price.qty -= 1
      this.selectedPriceOptions = JSON.parse(JSON.stringify(this.selectedPriceOptions))
    },
    addQty(price) {
      if (!price.qty) {
        price.qty = 0
      }

      let totalQty = this.selectedPriceOptions.reduce((total, item) => {
        return total + (item.qty || 0)
      }, 0)
      if (this.buyLimit && totalQty >= this.buyLimit) {
        return
      }
      price.qty += 1
      this.selectedPriceOptions = JSON.parse(JSON.stringify(this.selectedPriceOptions))
    },
    storeValueAmountInput(event, price) {
      let val = event.target.value.match(/^\d*(\.?\d{0,2})/g)[0]
      this.$nextTick(() => {
        this.$set(price, 'storeValueAmount', val)
      })
    },
    validForm() {
      if (!this.isValueCard) {
        // 折扣卡
        if (!this.selectedSaleId) {
          return '请选择会员卡类型'
        }
      } else {
        // 储值卡
        if (this.selectedPriceOptions.length === 0) {
          return '请选择要储值的金额'
        }
        for (const price of this.selectedPriceOptions) {
          // 自定义充值金额
          if (price.isCustom) {
            if (!price.storeValueAmount) {
              return '请填写自定义金额'
            }
            if (price.storeValueAmount < this.cardInfo.marketingRule.rechargeMinAmount || price.storeValueAmount > this.cardInfo.marketingRule.rechargeMaxAmount) {
              return `${this.customAmountPlaceholder}元`
            }
          }
        }
      }
      return ''
    },
    getPriceFormParam() {
      const errMsg = this.validForm()
      if (errMsg) {
        this.$toast(errMsg)
        return false
      }
      // 折扣卡
      if (!this.isValueCard) {
        return [
          {
            saleId: this.selectedSaleId,
            qty: 1
          }
        ]
      }
      // 储值卡
      return this.selectedPriceOptions
        .filter((x) => x.qty > 0)
        .map((y) => {
          return {
            saleId: y.isCustom ? '' : y.id,
            price: y.price,
            qty: y.qty,
            storeValueAmount: y.storeValueAmount
          }
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.prices-option-wrapper {
  width: 100%;
  padding: 16px 24px;
  background-color: $color-white;
  .prices-option-list {
    display: flex;
    width: 100%;
    align-items: center;
    overflow-x: scroll;
    .prices-option {
      flex: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-width: 184px;
      min-height: 94px;
      padding: 10px 16px;
      color: $color-brand;
      // 这里rgba用color-brand颜色变量，加载不出来，因为color-brand用了var的缘故
      background-color: rgba(#f27b2f, 0.06);
      border: 2px solid rgba(#f27b2f, 0.06);
      border-radius: 12px;
      &.active {
        border: 2px solid $color-brand;
      }
      &:not(:last-child) {
        margin-right: 24px;
      }
      .prices-option-name {
        font-size: 30px;
        text-align: center;
        white-space: nowrap;
      }
      .prices-option-desc {
        margin-top: 8px;
        font-size: 20px;
        text-align: center;
        white-space: nowrap;
      }
    }
  }
}
.price-row {
  position: relative;
  display: flex;
  width: 100%;
  padding: 24px 24px;
  align-items: center;
  flex-wrap: nowrap;
  background-color: $color-white;
  &:not(:last-child)::after {
    content: '';
    position: absolute;
    pointer-events: none;
    box-sizing: border-box;
    left: 24px;
    right: 24px;
    bottom: 0;
    border-bottom: 1px solid $color-border;
  }
  .price-row-label {
    flex: 1;
    display: flex;
    align-items: center;
    line-height: 48px;
    font-size: 30px;
    color: $color-text-main;
    .price-row-label_desc {
      margin-left: 16px;
      font-size: 24px;
      color: $color-text-sub;
    }
    .price-row-label_input {
      width: 288px;
      height: 48px;
      padding: 0 16px;
      margin-left: 16px;
      font-size: 24px;
      border: 1px solid $color-brand;
      border-radius: 4px;
    }
  }
  .price-row_value {
    flex: 0 0 auto;
    margin-left: 24px;
    font-size: 30px;
  }
  .price-row_icon {
    flex: 0 0 auto;
    margin-left: 24px;
    font-size: 40px;
    color: $color-text-disabled;
    &.active {
      color: $color-brand;
    }
    .iconfont {
      font-size: 40px;
    }
  }
  .price-row-btn {
    flex: 0;
    display: flex;
    align-items: center;
    .item-btn {
      // width: 40px;
      // height: 40px;
      position: relative;
      font-size: 0;
      &.minus-btn {
        opacity: 0.8;
      }
      &.disabled {
        opacity: 0.5 !important;
      }
      .item-btn_icon {
        color: $color-brand;
        font-size: 40px;
      }
      .item-btn_overlay {
        position: absolute;
        top: -10px;
        left: -10px;
        display: block;
        width: 60px;
        height: 60px;
        z-index: 2;
      }
    }
    .item-qty {
      display: inline-block;
      min-width: 50px;
      font-size: 26px;
      font-weight: bold;
      text-align: center;
      color: $color-text-main;
    }
  }
}
/deep/ .price-input-placeholder {
  color: $color-text-sub;
  font-size: 20px;
}
</style>
