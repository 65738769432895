/*
 * @Author: hwu
 * @Date: 2021-09-21 01:08:02
 * @Description: 会员卡相关
 * @LastEditTime: 2022-10-27 16:55:10
 */

import { cpHttp } from '@/utils/axios'

/**
 *获取会员卡详情
 * @param {String} cardId 会员卡Id
 * @param {String} userId 用户openId
 * @param {Number} way 购买渠道
 * @param {String} storeId 门店Id
 */
export const getCardDetailApi = (cardId, userId, way, wayId, storeId) => cpHttp.get('/coupon/detail/for/card', { params: { cardId, userId, way, wayId, storeId } })
