<!--
 * @Author: hwu
 * @Date: 2021-11-10 13:36:21
 * @Description: 门店选择弹窗
 * @LastEditTime: 2022-05-18 17:58:19
-->
<template>
  <tree-select-popup
    :show="show"
    :data="storeData"
    :title="popupTitle"
    :allSelectTitle="allSelectTitle"
    :height="height"
    :rowNum="rowNum"
    :editable="editable"
    :multi="multi"
    :emptyText="emptyText"
    :showLeftBtn="customShowLeftBtn"
    :showRightBtn="showRightBtn"
    :leftBtnText="leftBtnText"
    :rightBtnText="customRightBtnText"
    :selectedIds="selectedIds"
    :otherSelectedIds="otherSelectedIds"
    :disableOtherSelected="disableOtherSelected"
    :disabledIds="disabledIds"
    @onLeftClick="onLeftClick"
    @onRightClick="onRightClick"
    @onOverlayClick="onOverlayClick"
  />
</template>
<script>
import TreeSelectPopup from '@/components/common/TreeSelectPopup'
import { getStoreComponentDataApi } from '@/api/store'
export default {
  name: 'store-select-popup',
  components: { TreeSelectPopup },
  props: {
    show: { type: Boolean, required: true, desc: '是否显示弹窗', default: false },
    title: { type: String, required: false, desc: '适用的门店', default: '' },
    allSelectTitle: { type: String, required: false, desc: '全选的标题', default: '所有门店' },
    height: { type: String, required: false, desc: '弹窗高度', default: '90%' },
    rowNum: { type: Number, required: false, desc: '每行显示按钮数', default: 3 },
    editable: { type: Boolean, required: false, desc: '是否可以编辑', default: true },
    multi: { type: Boolean, required: false, desc: '是否支持多选', default: true },
    showLeftBtn: { type: Boolean, required: false, desc: '是否显示底部左侧按钮', default: true },
    showRightBtn: { type: Boolean, required: false, desc: '是否显示底部右侧按钮', default: true },
    leftBtnText: { type: String, required: false, desc: '底部左侧按钮文本', default: '返回' },
    rightBtnText: { type: String, required: false, desc: '底部右侧按钮文本', default: '' },
    selectedIds: { type: Array, required: false, desc: '已选中的Id列表', default: () => [] },
    otherSelectedIds: { type: Array, required: false, desc: '已被其他对象绑定的Id列表', default: () => [] },
    disableOtherSelected: { type: Boolean, required: false, desc: '是否禁用已被其他对象绑定的选项', default: true },
    disabledIds: { type: Array, required: false, desc: '被禁用的Id列表', default: () => [] },
    brandCode: { type: String, required: true, desc: '品牌编号', default: '' }
  },
  data () {
    return {
      popupTitle: '',
      storeData: [],
      emptyText: '没有符合条件的门店',
      customRightBtnText: '选好了',
      customShowLeftBtn: true
    }
  },
  watch: {
    brandCode: {
      handler (val) {
        if (val) {
          this.getStoreComponentData()
        }
      },
      immediate: true
    }
  },
  created () {
    // 标题
    if (!this.title) {
      if (this.editable) {
        this.popupTitle = '选择门店'
      } else {
        this.popupTitle = `适用的门店`
      }
    }
    // 底部栏
    if (this.rightBtnText) {
      // 如果自定义了底部栏信息，则按照定义的来
      this.customRightBtnText = this.rightBtnText
      this.customShowLeftBtn = this.showLeftBtn
    } else {
      // 如果没有自定义，则按照默认的来区分编辑和查看
      if (this.editable) {
        this.customRightBtnText = '选好了'
        this.customShowLeftBtn = true
      } else {
        this.customRightBtnText = '返回'
        this.customShowLeftBtn = false
      }
    }
  },
  methods: {
    getStoreComponentData () {
      if (!this.brandCode) {
        return false
      }
      this.emptyText = '门店加载中'
      getStoreComponentDataApi(this.brandCode).then((res) => {
        this.emptyText = '没有符合条件的数据'
        if (res.status !== '1' || !res.data) {
          this.storeData = []
        }
        this.storeData = res.data.dataList
      })
    },
    onOverlayClick (val) {
      this.$emit('update:show', false)
      this.$emit('onOverlayClick', val)
    },
    onLeftClick (val) {
      this.$emit('update:show', false)
      this.$emit('onLeftClick', val)
    },
    onRightClick (val) {
      this.$emit('update:show', false)
      this.$emit('onRightClick', val)
    }
  }
}
</script>
